export default {
  emailRequestSentSuccessfully: "Die Email wurde erfolgreich versendet",
  connect8DeliveryTitle: "Connect8 Lieferung",
  connect8DeliveryText:
    "Wollen Sie die Ware über das Connect8 Logistik Portal transportieren lassen?",
  selfDeliveryTitle: "Eigene Lieferung",
  selfDeliveryText: "Wollen Sie die Ware selbst liefern?",
  selfDeliverySuccess: "Die Lieferart wurde erfolgreich gesetzt",
  connect8DeliverySuccess: "Ihre Transportanfrage wurde erfolgreich erstellt!",
  tradeproductDeactivationSuccessfullyTitle: "Anfrage gelöscht",
  tradeproductDeactivationSuccessfullyText:
    "Ihre Produktanfrage wurde erfolgreich gelöscht",
  oneTimePassword: "Geben Sie das Einmal-Passwort ein",
  // ForgotPasswordMail
  forgotPasswordTitle: "Connect8 Password Reset",
  forgotPasswordVerificationCode: "Here's your verification code",
  forgotPasswordLink: "Or click the link",
  forgotPasswordResetPassword: "Reset Password",
  // InvoiceMail
  invoiceMailTitle: "Your Invoice for",
  invoiceMailTextAttachmentInfo:
    "In the attachments, you will find this month's invoice.",
  invoiceMailText1: "You can find more details in your company account at ",
  invoiceMailText2:
    "The amount will be deducted automatically from your default payment method.",
  // SignupMail
  signupMailTitle: "Welcome to Connect8!",
  signupMailText1: "Your account was created successfully!",
  signupMailText2:
    "We will validate the entered data and activate your account if everything is fine - otherwise we will contact you via E-Mail!",
};
