import de from "~/locales/de/common";
import de_footer from "~/locales/de/footer";
// import de_validation from "~/locales/de/validation";
// import en_validation from "~/locales/en/validation";
import de_enum from "~/locales/de/enum";
import de_error from "~/locales/de/error";
import de_message from "~/locales/de/message";
import de_registercompany from "~/locales/de/registercompany";
import de_shop from "~/locales/de/shop";
import de_task from "~/locales/de/task";
import de_zod from "~/locales/de/zod";
import en from "~/locales/en/common";
import en_enum from "~/locales/en/enum";
import en_error from "~/locales/en/error";
import en_footer from "~/locales/en/footer";
import en_message from "~/locales/en/message";
import en_registercompany from "~/locales/en/registercompany";
import en_shop from "~/locales/en/shop";
import en_task from "~/locales/en/task";
import en_zod from "~/locales/en/zod";

// This is the list of languages your application supports
export const supportedLngs = ["de", "en"];

// This is the language you want to use in case
// if the user language is not in the supportedLngs
export const fallbackLng = "en";

// The default namespace of i18next is "translation", but you can customize it here
export const defaultNS = "translation";

export const resources = {
  en: {
    translation: en,
    // validation: en_validation,
    footer: en_footer,
    registercompany: en_registercompany,
    error: en_error,
    task: en_task,
    shop: en_shop,
    enum: en_enum,
    message: en_message,
    zod: en_zod,
  },
  de: {
    translation: de,
    // validation: de_validation,
    footer: de_footer,
    registercompany: de_registercompany,
    error: de_error,
    task: de_task,
    shop: de_shop,
    enum: de_enum,
    message: de_message,
    zod: de_zod,
  },
};
