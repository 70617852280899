export default {
  //#region registercompany
  titleRegisterCompany: "Register new Company",
  nameBasic: "Basics",
  nameBusiness: "Business",
  nameRepresent: "Represent",
  nameExecutive: "Executive",
  nameOwner: "Owner",
  namePublic: "Public",
  nameIdentity: "Identity check",
  nameConfirm: "Confirm Data",
  nameSuccess: "Success",
  //#endregion registercompany
  //#region basic
  basicPrimaryText: "Let's start with some basics",
  basicSecondaryText: "Select your location and businesstype",
  basicBusinessLocation: "Business Location",
  basicTypeOfBusiness: "Type of Business",
  basicBusinessStructureType: "Business structure type",
  //#endregion basic
  //#region business
  businessPrimaryText: "Describe your Company",
  businessSecondaryText:
    "Connect8 collects information to protect and support our customers as good as we can. Furthermore it is important to fulfill our",
  businessAgreement: "Agreement",
  businessCompanyName: "Legal Companyname",
  businessCompanyID: "Company register ID",
  businessVAT: "VAT number (UID) optional",
  businessIndustryType: "Industry type",
  businessHomepage: "Homepage",
  //#endregion business
  //#region represent
  representPrimaryText: "Represent of the company",
  representSecondaryText:
    " We need someone who is legaly allowed to represent the company. If it is not you - stop here and call this person.",
  representFirstName: "Firstname",
  representLastName: "Lastname",
  representJobTitle: "Job title",
  representPhoneNumber: "Phone number",
  representOwnership: "Ownership percentage",
  //endregion represent
  //#region executive
  executivePrimaryText: "Executive of the company",
  executiveSecondaryTextPart1:
    "Here you should enter the executives of your company. This is an optional step - ",
  executiveSecondaryTextPart2:
    "but if we get some sort of problems - we will try to check the national companyregister. If we find something suspicious we need to report this immediately.",
  executiveFirstName: "Firstname",
  executiveLastName: "Lastname",
  //#endregion executive
  //#region owner
  ownerPrimaryText: "Owner of the company",
  ownerSecondaryTextPart1:
    "Here you should enter the owners of your company. This is an optional step - ",
  ownerSecondaryTextPart2:
    "but if we get some sort of problems - we will try to check the national companyregister. If we find something suspicious we need to report this immediately.",
  ownerSecondaryTextPart3: "An Owner got at least 25% of the company.",
  ownerFirstName: "Firstname",
  ownerLastName: "Lastname",
  //#endregion owner
  //#region public
  publicPrimaryText: "Public information",
  publicSecondaryTextPart: "Enter some public information about your company.",
  publicPhoneNumber: "Customer support phone number",
  //#endregion public
  //#region identity
  identityPrimaryText: "Proof your identity",
  identitySecondaryTextPart1:
    "We want to protect our customers as good as we can. So we want to follow the",
  identitySecondaryTextPart2: "Know Your Customer(KYC)-Obligation",
  identitySecondaryTextPart3: "Therefore we need to approve your identity.",
  identitySecondaryTextPart4:
    "If you are on mobile, please use the phone horizontally to take the picture.",
  identityPassport: "Passport",
  //#endregion identity
  //#region confirm
  confirmEmail: "Companylogin E-Mail",
  confirmPassword: "Companylogin password",
  //#endregion confirm
  //#region success
  successText:
    "Your Registration is completed - after your Account has been activated you can use our App!",
  //#endregion success
  //#region errors
  errorRepresentMissing: "Data for Represent is missing!",
  errorIdentityDataMissing: "Data for Identity Check is missing!",
  errorCreateLogin: "The company data couldn't be created successfully",
  errorStepsMissing: "There are some steps missing!",
  errorUploadingPassport: "Error while uploading the passport",
  errorInvalidPassport: "Error: Passport is invalid or not readable",
  errorInvalidWebcamPhoto: "Error: Webcam picture invalid",
  errorUploadingWebcamPhoto: "Error while uploading the webcam picture",
  //#endregion errors
};
