export default {
  //#region registercompany
  titleRegisterCompany: "Neue Firma registrieren",
  nameBasic: "Grundlagen",
  nameBusiness: "Geschäft",
  nameRepresent: "Vertreter",
  nameExecutive: "Geschäftsführer",
  nameOwner: "Geschäftsinhaber",
  namePublic: "Öffentlich",
  nameIdentity: "Identitätsprüfung",
  nameConfirm: "Bestätigung der Daten",
  nameSuccess: "Erfolg",
  //#endregion registercompany
  //#region basic
  basicPrimaryText: "Beginnen wir mit einigen Grundlagen",
  basicSecondaryText: "Wählen Sie Ihren Standort und Unternehmenstyp aus",
  basicBusinessLocation: "Geschäftsstandort",
  basicTypeOfBusiness: "Geschäftsart",
  basicBusinessStructureType: "Art der Geschäftsstruktur",
  //#endregion basic
  //#region business
  businessPrimaryText: "Beschreiben Sie Ihr Unternehmen",
  businessSecondaryText:
    "Connect8 sammelt Informationen, um unsere Kunden so gut wie möglich zu schützen und zu unterstützen. Darüber hinaus ist es wichtig, unsere Vereinbarung zu erfüllen",
  businessAgreement: "Vereinbarung",
  businessCompanyName: "Rechtlicher Firmenname",
  businessCompanyID: "Firmenbuchnummer",
  businessVAT: "UID optional",
  businessIndustryType: "Branchentyp",
  businessHomepage: "Webseite",
  //#endregion business
  //#region represent
  representPrimaryText: "Vertreter des Unternehmens",
  representSecondaryText:
    " Wir brauchen jemanden, der gesetzlich berechtigt ist, das Unternehmen zu vertreten. Wenn Sie es nicht sind, stoppen Sie hier und rufen Sie diese Person.",
  representFirstName: "Vorname",
  representLastName: "Nachname",
  representJobTitle: "Stellenbezeichnung",
  representPhoneNumber: "Telefonnummer",
  representOwnership: "Eigentumsprozentsatz",
  //endregion represent
  //#region executive
  executivePrimaryText: "Geschäftsführer des Unternehmens",
  executiveSecondaryTextPart1:
    "Hier sollten Sie die Führungskräfte Ihres Unternehmens eintragen. Dies ist ein optionaler Schritt - ",
  executiveSecondaryTextPart2:
    "Sollten jedoch Probleme auftreten, werden wir versuchen, das nationale Unternehmensregister zu überprüfen. Wenn wir etwas Verdächtiges finden, müssen wir dies sofort melden.",
  executiveFirstName: "Vorname",
  executiveLastName: "Nachname",
  //#endregion executive
  //#region owner
  ownerPrimaryText: "Eigentümer der Firma",
  ownerSecondaryTextPart1:
    "Hier sollten Sie die Inhaber Ihres Unternehmens eintragen. Dies ist ein optionaler Schritt - ",
  ownerSecondaryTextPart2:
    "Sollten jedoch Probleme auftreten, werden wir versuchen, das nationale Unternehmensregister zu überprüfen. Wenn wir etwas Verdächtiges finden, müssen wir dies sofort melden.",
  ownerSecondaryTextPart3:
    "Ein Eigentümer hat mindestens 25 % des Unternehmens erworben.",
  ownerFirstName: "Vorname",
  ownerLastName: "Nachname",
  //#endregion owner
  //#region public
  publicPrimaryText: "Öffentliche informationen",
  publicSecondaryTextPart:
    "Geben Sie einige öffentliche Informationen über Ihr Unternehmen ein.",
  publicPhoneNumber: "Telefonnummer des Kundensupports",
  //#endregion public
  //#region identity
  identityPrimaryText: "Beweisen Sie Ihre Identität",
  identitySecondaryTextPart1:
    "Wir wollen unsere Kunden bestmöglich schützen. Deshalb wollen wir dem folgen",
  identitySecondaryTextPart2: "Know Your Customer(KYC)-Verfahren",
  identitySecondaryTextPart3: "Daher müssen wir Ihre Identität bestätigen.",
  identitySecondaryTextPart4:
    "Wenn Sie ein Mobiltelefon verwenden, verwenden Sie das Telefon bitte horizontal, um das Bild aufzunehmen.",
  identityPassport: "Personalausweis",
  //#endregion identity
  //#region confirm
  confirmEmail: "Firmenlogin E-Mail",
  confirmPassword: "Firmenlogin password",
  //#endregion confirm
  //#region success
  successText:
    "Ihre Registrierung ist abgeschlossen – nach der Aktivierung Ihres Kontos können Sie unsere App nutzen!",
  //#endregion success
  //#region errors
  errorRepresentMissing: "Die Daten des Firmenvertreters sind unvollständig!",
  errorIdentityDataMissing:
    "Die Daten für die Identitätsprüfung sind unvollständig!",
  errorCreateLogin:
    "Die Firmendaten konnten nicht erfolgreich erstellt werden!",
  errorStepsMissing: "Es wurden noch nicht alle Punkte abgeschlossen!",
  errorUploadingPassport: "Fehler beim Hochladen des Ausweisdokuments!",
  errorInvalidPassport: "Fehler: Ausweis ist ungültig oder nicht lesbar",
  errorInvalidWebcamPhoto: "Fehler: Webcam Foto ungültig",
  errorUploadingWebcamPhoto: "Fehler beim Hochladen des Webcam Fotos!",
  //#endregion errors
};
