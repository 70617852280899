import {
  MESSAGE_PLACEHOLDER_PRODUCT,
  MESSAGE_PLACEHOLDER_USERNAME,
} from "~/constants/shopConstants";

export default {
  messageCounterOffer: `${MESSAGE_PLACEHOLDER_USERNAME} hat ein neues Angebot geschickt!`,
  messageDeclineOffer: `${MESSAGE_PLACEHOLDER_USERNAME} hat den Handel abgebrochen.`,
  messageAcceptOfferPartial: `${MESSAGE_PLACEHOLDER_USERNAME} hat den Handel akzeptiert! Wechseln Sie in die Übersicht, um den Handel abzuschließen!`,
  messageAcceptOfferFull: `Der Handel zu ${MESSAGE_PLACEHOLDER_PRODUCT} ist vollständig abgeschlossen!`,
  messageAcceptOfferFullDelivery: `Der Handel zu ${MESSAGE_PLACEHOLDER_PRODUCT} ist vollständig abgeschlossen! Wählen Sie jetzt die Lieferart!`,
  messageNewOffer: `Ein neues Angebot zu Ihrer Anfrage wurde abgegeben!`,
  messageAcceptTransportFull: `Ein Handel wurde vollständig abgeschlossen! Prüfen Sie die Übersicht.`,
};
