export default {
  emailRequestSentSuccessfully: "The mail was sent successfully",
  connect8DeliveryTitle: "Connect8 Delivery",
  connect8DeliveryText:
    "Do you want to have the goods transported via the Connect8 logistics portal?",
  selfDeliveryTitle: "Self Delivery",
  selfDeliveryText: "Do you want to deliver the goods yourself?",
  selfDeliverySuccess: "Delivery type was set successfully!",
  connect8DeliverySuccess: "Your logistic request was created successfully!",
  tradeproductDeactivationSuccessfullyTitle: "Request Deleted",
  tradeproductDeactivationSuccessfullyText:
    "Your product request was deleted successfully",
  oneTimePassword: "Enter the one time Password",
  // ForgotPasswordMail
  forgotPasswordTitle: "Connect8 Passwort zurücksetzen",
  forgotPasswordVerificationCode: "Hier ist Ihr Verifikations Code",
  forgotPasswordLink: "oder klicken Sie auf den Link",
  forgotPasswordResetPassword: "Passwort zurücksetzen",
  // InvoiceMail
  invoiceMailTitle: "Ihre Rechnung für",
  invoiceMailTextAttachmentInfo:
    "Sie finden die Rechnung für diesen Monat in den Anhängen.",
  invoiceMailText1: "Weitere Details finden Sie in Ihrem Firmenkzugang auf ",
  invoiceMailText2:
    "Der Betrag wird automatisch mit ihrer hinterlegten Zahlungsmethode beglichen.",
  // SignupMail
  signupMailTitle: "Willkommen auf Connect8!",
  signupMailText1: "Ihr Konto wurde erfolgreich erstellt!",
  signupMailText2:
    "Wir werden Ihre eingegebenen Daten überprüfen und Ihr Konto aktivieren. Sollten die Daten unvollständig sein, werden wir Sie per E-Mail kontaktieren.",
};
